import request from "../utils/request";
// 发送验证码--忘记密码专用
export function getVerifyCode(data) {
  return request({
    url: "/user/forget_password_send_verify_code",
    method: "post",
    data,
  });
}
// /user/terminal_login
export function terminalLogin(data) {
  return request({
    url: "/user/terminal_login",
    method: "post",
    data,
  });
}
// /user/send_verify_code
export function sendVerifyCode(data) {
  return request({
    url: "/user/send_verify_code",
    method: "post",
    data,
  });
}
// /user/login 密码登录
export function login(data) {
  return request({
    url: "/user/pc_login",
    method: "post",
    data,
  });
}
// /user/pc_terminal_register 手机注册
export function terminalRegister(data) {
  return request({
    url: "/user/pc_terminal_register",
    method: "post",
    data,
  });
}

// 测试api /test/test_decode
export function test(data) {
  return request({
    url: "/test/test_decode",
    method: "post",
    data,
  });
}
// /app/create_ft_key 申请临时加密秘钥-网页用
export function key(data) {
  return request({
    url: "/app/create_ft_key",
    method: "post",
    data,
  });
}
// 忘记账号密码查询绑定邮箱 /user/pc_query_phone
export function queryPhone(data) {
  return request({
    url: "/user/pc_query_phone",
    method: "post",
    data,
  });
}

// /user/forget_password_modify 忘记密码 /pc_forget_password
export function forgetPassword(data) {
  return request({
    url: "/user/pc_forget_password",
    method: "post",
    data,
  });
}

// 请求二维码 /user/pc_refresh_qr_code
export function refresh_qr_code(data) {
  return request({
    url: "/user/pc_refresh_qr_code",
    method: "post",
    data,
  });
}
// /user/pc_query_result 查询结果=>user/pc_query_qr_code
export function query_result(data) {
  return request({
    url: "user/pc_query_qr_code",
    method: "post",
    data,
  });
}

// 实名认证绑定 /identity/bind
export function bindID(data) {
  return request({
    url: "identity/bind",
    method: "post",
    data,
  });
}
// /user/forget_password_verify_code 忘记密码_效验验证码
export function forget_password_verify_code(data) {
  return request({
    url: "user/forget_password_verify_code",
    method: "post",
    data,
  });
}
// /bind_phone /user/bind_phone
export function bindPhone(data) {
  return request({
    url: "/user/bind_phone",
    method: "post",
    data,
  });
}

// /user/google_login
export function googleLogin(data) {
  return request({
    url: "/user/google_login",
    method: "post",
    data,
  });
}
// /user/bind_email
export function bindEmail(data) {
  return request({
    url: "/user/bindEmail",
    method: "post",
    data,
  });
}

// /user/apple_login
export function appleLogin(data) {
  return request({
    url: "/user/apple_login",
    method: "post",
    data,
  });
}
// facebook_login
export function fbLogin(data) {
  return request({
    url: "/user/facebook_login",
    method: "post",
    data,
  });
}

// steam_login
export function steamLogin(data) {
  return request({
    url: "/user/steam_login",
    method: "post",
    data,
  });
}

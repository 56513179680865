<template>
  <div class="code_login">
    <div class="code_box">
      <img :src="imgsrc"
           alt="">
      <div class="code_mosk"
           v-if="moskFlag"
           @click="flashCode">
        <p class="code_tips">二维码已失效</p>
        <div class="flash_code">
          <span class="passIconfont icon-ftshuaxin flash_icon"></span><span>点击刷新</span>
        </div>
      </div>
    </div>
    <div class="tips">
      打开 <span class="red">猫之城移动端</span>,扫一扫登录
    </div>
    <div class="goback1">
      <router-link to="/phonelogin"
                   @click="goback">
        <img src="./goback.png"
             alt="">
      </router-link>
    </div>
  </div>
</template>

<script>
import { setLocalStorage, setSessionStorage, getSessionStorage } from "@/utils/stage"
import { refresh_qr_code, query_result } from "@/api/login"
export default {
  data () {
    return {
      imgsrc: '',
      qr_code_token: '',
      queryResultFlag: false,
      timer1: null,
      timer: null,
      moskFlag: false,
      configData: ''
    }
  },
  watch: {
    queryResultFlag (newVal) {
      if (newVal) {
        this.queryResult()
      } else {
        window.clearInterval(this.timer)
      }
    }
  },
  created () {
    this.init()
    // this.flag()
  },
  methods: {
    init () {
      this.configData = JSON.parse(getSessionStorage('message'))
      const msg = Object.assign(JSON.parse(JSON.stringify(this.configData)), {})
      refresh_qr_code(msg).then(res => {
        this.imgsrc = res.data.qr_code
        this.qr_code_token = res.data.qr_code_token
        this.queryResultFlag = true
      })
    },
    goback () {
      window.clearInterval(this.timer)
    },
    queryResult () {
      window.clearInterval(this.timer)
      this.moskFlag = false
      const msg = Object.assign(JSON.parse(JSON.stringify(this.configData)), { qr_code_token: this.qr_code_token })
      this.timer = setInterval(() => {
        query_result(msg).then(res => {
          if (res.data.status === 3) {
            setSessionStorage('loginAccount', res.data.user_entity.phone)
            setLocalStorage('accountStorage', JSON.stringify(res.data.user_entity))
            if (res.data.user_entity.identity_no === null) {
              this.$router.push({ path: '/bindID' })
            } else {
              this.$router.push({ path: '/success' })
            }
            window.clearInterval(this.timer)
          } else if (res.data.status === 100) {
            this.moskFlag = true
            window.clearInterval(this.timer)
            this.queryResultFlag = false

          }
        })
      }, 5000)
    },
    flashCode () {
      this.init()
    }
  }
}
</script>

<style lang="scss" scope>
@media (min-width: 1600px) {
  .code_login {
    width: 100%;
    height: 100%;
    .code_box {
      width: 249px;
      height: 249px;
      border: 1px solid #ccc;
      margin: 0 auto;
      position: relative;
      .code_mosk {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        .code_tips {
          text-align: center;
          font-size: 26px;
          font-family: "SimHei";
          font-weight: 400;
          line-height: 38px;
          color: #ffffff;
          text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.37);
          padding-top: 50px;
          box-sizing: border-box;
        }
        .flash_code {
          width: 156px;
          height: 49px;
          margin: 0 auto;
          background: #ececec;
          text-align: center;
          font-size: 22px;
          font-family: "SimHei";
          font-weight: 400;
          line-height: 49px;
          color: #646464;
          cursor: pointer;
          box-sizing: border-box;
          margin-top: 40px;
          border-radius: 6px;
          .flash_icon {
            font-size: 22px;
            margin-right: 10px;
          }
        }
      }
    }
    .tips {
      margin-top: 30px;
      text-align: center;
      font-size: 28px;
      .red {
        color: #ff7391;
      }
    }
    .goback1 {
      width: 140px;
      height: 140px;
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .code_login {
    width: 100%;
    height: 100%;
    .code_box {
      width: 249 * 0.85px;
      height: 249 * 0.85px;
      border: 1 * 0.85px solid #ccc;
      margin: 0 auto;
      position: relative;
      .code_mosk {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        .code_tips {
          text-align: center;
          font-size: 26 * 0.85px;
          font-family: "SimHei";
          font-weight: 400;
          line-height: 38 * 0.85px;
          color: #ffffff;
          text-shadow: 0 * 0.85px 3 * 0.85px 6 * 0.85px rgba(0, 0, 0, 0.37);
          padding-top: 50 * 0.85px;
          box-sizing: border-box;
        }
        .flash_code {
          width: 156 * 0.85px;
          height: 49 * 0.85px;
          margin: 0 auto;
          background: #ececec;
          text-align: center;
          font-size: 22 * 0.85px;
          font-family: "SimHei";
          font-weight: 400;
          line-height: 49 * 0.85px;
          color: #646464;
          cursor: pointer;
          box-sizing: border-box;
          margin-top: 40 * 0.85px;
          border-radius: 6 * 0.85px;
          .flash_icon {
            font-size: 22 * 0.85px;
            margin-right: 10 * 0.85px;
          }
        }
      }
    }
    .tips {
      margin-top: 30 * 0.85px;
      text-align: center;
      font-size: 28 * 0.85px;
      .red {
        color: #ff7391;
      }
    }
    .goback1 {
      width: 140 * 0.85px;
      height: 140 * 0.85px;
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }
  }
}
@media screen and (max-width:1199px) {
  .code_login {
    width: 100%;
    height: 100%;
    .code_box {
      width: 249 * 0.7px;
      height: 249 * 0.7px;
      border: 1 * 0.7px solid #ccc;
      margin: 0 auto;
      position: relative;
      .code_mosk {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        .code_tips {
          text-align: center;
          font-size: 26 * 0.7px;
          font-family: "SimHei";
          font-weight: 400;
          line-height: 38 * 0.7px;
          color: #ffffff;
          text-shadow: 0 * 0.7px 3 * 0.7px 6 * 0.7px rgba(0, 0, 0, 0.37);
          padding-top: 50 * 0.7px;
          box-sizing: border-box;
        }
        .flash_code {
          width: 156 * 0.7px;
          height: 49 * 0.7px;
          margin: 0 auto;
          background: #ececec;
          text-align: center;
          font-size: 22 * 0.7px;
          font-family: "SimHei";
          font-weight: 400;
          line-height: 49 * 0.7px;
          color: #646464;
          cursor: pointer;
          box-sizing: border-box;
          margin-top: 40 * 0.7px;
          border-radius: 6 * 0.7px;
          .flash_icon {
            font-size: 22 * 0.7px;
            margin-right: 10 * 0.7px;
          }
        }
      }
    }
    .tips {
      margin-top: 30 * 0.7px;
      text-align: center;
      font-size: 28 * 0.7px;
      .red {
        color: #ff7391;
      }
    }
    .goback1 {
      width: 140 * 0.7px;
      height: 140 * 0.7px;
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }
  }
}
</style>
